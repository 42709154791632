body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: rgb(249, 219, 198);
}
header {
  background-color: rgb(249, 219, 198); /* Brushpetals primary color background-color: #F9DBC6;*/
  color: rgb(107, 156, 68); /* Brushpetals secondary color #6B9C44*/
  padding: 20px;
  text-align: center;
}

header section {
  position: absolute;
  width: 45px;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgb(249, 219, 198);
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(107, 156, 68);
}
h1,
h2 {
  color: rgb(107, 156, 68); /* Brushpetals secondary color */
}
p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-link {
  color: rgb(107, 156, 68);
  display: flex;
  justify-content: center;
  width: 100%;
  border: 2px solid rgb(107, 156, 68);
  padding: 10px 0;
  margin-bottom: 20px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  box-shadow: 3px 3px 5px rgb(107, 156, 68);
}

.App-link svg {
  height: 30px;
}
.App-link span {
  padding-left: 10px;
}

.App-footer {
  padding: 20px 0;
  margin: 60px 0;
  color: rgb(107, 156, 68);
  font-weight: bold;
  justify-content: center;
  display: flex;
  border-top: 5px solid rgb(107, 156, 68);
}

.App-link img {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}